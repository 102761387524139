import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-6 g-xl-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card4 = _resolveComponent("Card4")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"d-flex flex-wrap flex-stack mb-6\"><h3 class=\"fw-bolder my-2\"> My Contacts <span class=\"fs-6 text-gray-400 fw-bold ms-1\">(59)</span></h3><div class=\"d-flex my-2\"><select name=\"status\" data-control=\"select2\" data-hide-search=\"true\" class=\"form-select form-select-white form-select-sm w-125px\"><option value=\"Online\" selected>Online</option><option value=\"Pending\">Pending</option><option value=\"Declined\">Declined</option><option value=\"Accepted\">Accepted</option></select></div></div>", 1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Card4, {
        name: "Emma Smith",
        position: "Art Director",
        online: false,
        avatar: "media/avatars/300-6.jpg",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card4, {
        name: "Melody Macy",
        position: "Marketing Analytic",
        online: true,
        initials: "M",
        color: "danger",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card4, {
        name: "Max Smith",
        position: "Software Enginer",
        online: false,
        avatar: "media/avatars/300-1.jpg",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card4, {
        name: "Sean Bean",
        position: "Web Developer",
        online: false,
        avatar: "media/avatars/300-5.jpg",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card4, {
        name: "Brian Cox",
        position: "UI/UX Designer",
        online: false,
        avatar: "media/avatars/300-25.jpg",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card4, {
        name: "Mikaela Collins",
        position: "Head Of Marketing",
        online: true,
        initials: "M",
        color: "warning",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card4, {
        name: "Francis Mitcham",
        position: "Software Arcitect",
        online: false,
        avatar: "media/avatars/300-9.jpg",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card4, {
        name: "Olivia Wild",
        position: "System Admin",
        online: true,
        initials: "O",
        color: "danger",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card4, {
        name: "Neil Owen",
        position: "Account Manager",
        online: true,
        initials: "N",
        color: "primary",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card4, {
        name: "Dan Wilson",
        position: "Web Desinger",
        online: false,
        avatar: "media/avatars/300-23.jpg",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card4, {
        name: "Emma Bold",
        position: "Corporate Finance",
        online: true,
        initials: "E",
        color: "danger",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      }),
      _createVNode(_component_Card4, {
        name: "Ana Crown",
        position: "Customer Relationship",
        online: false,
        avatar: "media/avatars/300-12.jpg",
        connected: false,
        "avg-earnings": "$14,560",
        "total-sales": "$236,400"
      })
    ]),
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"d-flex flex-stack flex-wrap pt-10\"><div class=\"fs-6 fw-bold text-gray-700\">Showing 1 to 10 of 50 entries</div><ul class=\"pagination\"><li class=\"page-item previous\"><a href=\"#\" class=\"page-link\"><i class=\"previous\"></i></a></li><li class=\"page-item active\"><a href=\"#\" class=\"page-link\">1</a></li><li class=\"page-item\"><a href=\"#\" class=\"page-link\">2</a></li><li class=\"page-item\"><a href=\"#\" class=\"page-link\">3</a></li><li class=\"page-item\"><a href=\"#\" class=\"page-link\">4</a></li><li class=\"page-item\"><a href=\"#\" class=\"page-link\">5</a></li><li class=\"page-item\"><a href=\"#\" class=\"page-link\">6</a></li><li class=\"page-item next\"><a href=\"#\" class=\"page-link\"><i class=\"next\"></i></a></li></ul></div>", 1))
  ], 64))
}