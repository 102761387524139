import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "col-md-6 col-xxl-4" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body d-flex flex-center flex-column p-9" }
const _hoisted_4 = { class: "mb-5" }
const _hoisted_5 = { class: "symbol symbol-75px symbol-circle" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 2,
  class: "symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3"
}
const _hoisted_8 = {
  href: "#",
  class: "fs-4 text-gray-800 text-hover-primary fw-bolder mb-0"
}
const _hoisted_9 = { class: "fw-bold text-gray-400 mb-6" }
const _hoisted_10 = { class: "d-flex flex-center flex-wrap mb-5" }
const _hoisted_11 = { class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3" }
const _hoisted_12 = { class: "fs-6 fw-bolder text-gray-700" }
const _hoisted_13 = { class: "border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3" }
const _hoisted_14 = { class: "fs-6 fw-bolder text-gray-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.avatar)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.avatar,
                  alt: ""
                }, null, 8, _hoisted_6))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass([`bg-light-${_ctx.color} text-${_ctx.color}`, "symbol-label fs-5 fw-bolder"])
                }, _toDisplayString(_ctx.initials), 3)),
            (_ctx.online)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.name), 1),
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.position), 1),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.avgEarnings), 1),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "fw-bold text-gray-400" }, "Avg. Earnings", -1))
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.totalSales), 1),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "fw-bold text-gray-400" }, "Total Sales", -1))
          ])
        ]),
        _createElementVNode("button", {
          class: "btn btn-sm btn-light-primary fw-bolder",
          "data-kt-drawer-toggle": "true",
          "data-kt-drawer-target": "#kt_drawer_chat",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDrawer()))
        }, " Send Message ")
      ])
    ])
  ]))
}